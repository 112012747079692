import { Injectable, Inject} from '@angular/core';

import { throwError } from 'rxjs';
import { WINDOW } from './windowProvider';


//import { DatePipe } from '@angular/common';

declare var $ : any;

@Injectable()
export class SharedService {

    constructor (
                 @Inject(WINDOW) private window: Window,
        ) {}

    getMyApiUrl(){
      if (this.window.location.hostname == "www.performatic.com.br"){
        return 'https://itsaplicacoes.azurewebsites.net/';
      }else{
        return 'http://localhost/ITSAplicacoesAPI/';
      }

    }

    getWhatsappNumber(){
      return '5511943959310';
      
    }
    
    handleErrorObservable(error: Response | any) {

        if(error.statusText == "Unauthorized"){
          window.location.href="#/auth/login";
          return;
        }

        if (error.error != undefined){
          console.error(error.error.ExceptionMessage);
          console.error(error.error.message || error);
          return throwError(error.error.message || error);

        }else{
          console.error(error);
          return throwError(error.message || error);

        }

      }


    //Formata data para salvar na base
    formatDate(date: any) {
        let dataTratada = "";
        if (date != null && date != ""){
          let stringToSplit = date;
          let x = stringToSplit.split("/");
          let day = x[0];
          let month = x[1];
          let year = x[2];
          let hAux: any;
          let hour = "";

          //Quando vem com a hora
          if (year.indexOf(":") > 0){
            hAux = x[2].split(" ");
            hour = hAux[1];
            year = hAux[0];
          }

          dataTratada = year +"/"+ month +"/"+ day + " " + hour;

        }

         return dataTratada//new Date(dataTratada).toISOString();
      }

      //DataTable com os botões de ações
      // apenas com B(Buttons) - F(find) - T(Table) - I(Information) - P(Paging)
      dtOptionsFull(orderIDColumn:number=1){

        return {
          pagingType: 'full_numbers',
          pageLength: 10,
          destroy: true,
          order: [[ orderIDColumn, "asc" ]],
          processing: true,
          stateSave: true,
          stateDuration: 1,
          responsive: false,
          lengthMenu: [[10, 30, 40, 100, -1], [10, 30, 40, 100, "Todos"]],
          oLanguage: {
            "sUrl": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Portuguese-Brasil.json"
          },
          buttons: [
            { extend: 'copy', text: 'Copiar' },
            { extend: 'pdf',
              text: 'PDF',
              exportOptions: {
                  columns: [1, 2]
                  }
            },
            { extend: 'print',
              text: 'Imprimir',
              exportOptions: {
                  columns: [1, 2]
                  }
            },
            { extend: 'excel',
              text: 'Excel',
              exportOptions: {
                columns: [1, 2]
                }
            },
            { extend: 'colvis',
              text: 'Omitir Colunas'
            }
          ],
          dom: "Bfrtip",
        };

      }

      //DataTable sem os botões
      // apenas com L(lenght) - F(find) - T(Table) - I(Information) - P(Paging)
      dtOptionsLFTIP(){

        return {
          pagingType: 'full_numbers',
          pageLength: 10,
          order: [[ 1, "asc" ]],
          processing: true,
          stateSave: true,
          stateDuration: 1,
          responsive: false,
          lengthMenu: [[10, 30, 40, 100, -1], [10, 30, 40, 100, "Todos"]],
          oLanguage: {
            "sUrl": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Portuguese-Brasil.json"
          },
          dom: "lfrtip",
        };

      }

      //DataTable sem os botões
      // apenas com F(find) - T(Table) - I(Information) - P(Paging)
      dtOptionsFTIP(){

        return {
          pagingType: 'full_numbers',
          pageLength: 10,
          order: [[ 1, "asc" ]],
          processing: true,
          stateSave: true,
          stateDuration: 1,
          responsive: false,
          lengthMenu: [[10, 30, 40, 100, -1], [10, 30, 40, 100, "Todos"]],
          oLanguage: {
            "sUrl": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Portuguese-Brasil.json"
          },
          dom: "ftip",
        };

      }

      dtOptionsTP(){

        return {
          pagingType: 'full_numbers',
          pageLength: 10,
          order: [[ 1, "asc" ]],
          processing: true,
          stateSave: true,
          stateDuration: 1,
          responsive: false,
          lengthMenu: [[10, 30, 40, 100, -1], [10, 30, 40, 100, "Todos"]],
          oLanguage: {
            "sUrl": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Portuguese-Brasil.json"
          },
          dom: "tp",
        };

      }


}
